import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'

export default function Swipercard(props) {
return (
    <div key={props.key} className='cardSlideContainer '>
                    <Card  className='cardContainer' >
                        <img src={props.imgsrc} alt='tower'  className='cardImg'  />
                        <CardContent className='cardContentContainer'>
                            <Grid container direction='column' spacing={2} justifyContent='space-between'>
                                <Grid item ><Typography variant='body1' className='cardDescr'>
                                    {props.descr}
                                </Typography></Grid>
                                <Grid item ><Typography variant='body1' className='cardCost'>
                                    {props.cost}
                                </Typography></Grid>
                                <Grid item ><Typography  variant='body1' className='cardLoc'>
                                    {props.loc}
                                </Typography></Grid>
                            </Grid>
                        </CardContent>
                    </Card>                      
    </div>
  )
}
