import React from "react";
import "./homePage.scss";
import City from "./components/city";
import HowItWorks from "./components/howitworks";
import LegalServices from "./components/legalservices";
import ContactUs from "./components/contactus";
import FeaturedList from "../homePage/components/FeaturedList";
import NavHomeSearch from "../homePage/components/NavHomeSearch";
import TopOffers from "../homePage/components/TopOffers";
import About from "../homePage/components/About";

import Footer from "../../Components/Footer";
import HomeBg from "../../assets/backgroundPattern.png"
const windowWidth=window.innerWidth;

function HomePage(props) {
  return (
    <div className="homepage">
      <img src={HomeBg} style={{width:"100%", position: "fixed", height: "100vh"}} className='homeBg' alt='backgroundimage'/>
      <NavHomeSearch />
      <TopOffers />
      <About/>
      {/* <HowItWorks/> */}
      {/* <FeaturedList /> */}
      <City/>
      {/* <LegalServices/> */}
      {/* <ContactUs/>       */}
      <Footer />
    </div>

  );
}
export default HomePage;
