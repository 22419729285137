import React, { useState, useEffect} from "react";
import "./components_forSalePage.scss";
import { Grid, SwipeableDrawer, Divider, Typography, Slider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandIcon from '../../../assets/ExpandMore.svg';
import SearchIconLight from '../../../assets/search-icon.svg';
import SearchIconDark from '../../../assets/search-outline.svg';
import FilterIcon from '../../../assets/filter-outline.svg';
import SortIcon from '../../../assets/swap-vertical-outline.svg';
import CloseIcon from '@mui/icons-material/Close';
import { FilterOptions } from './constants';
import { Cities } from "./constants";
import { PropertyType } from "./constants";
import { Bedrooms } from "./constants";
import { FurnishingStatus } from "./constants";
import { Availability } from "./constants";

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
        marginTop: theme.spacing(5)
    },
}));

export default function Filter(props) {

    const screenWidth = window.outerWidth;
    const [showDrawer, setShowDrawer] = useState(false);
    const [bigScreen,setBigScreen]=useState(false);

    const toggleDrawer = (isOpen) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowDrawer(isOpen)
    }; 

    const listenSmallScreenEvent = () => {
        if (screenWidth<=980) {
          setBigScreen(false);
        } else {
          setBigScreen(true);
        }
    };

    useEffect(listenSmallScreenEvent);

    function clickedButtonHandler(name){
        for(var i in props.chipData){
            if(props.chipData[i].label===name){
                return true;
            }
        }
        return false;
    };

    return (
        <div>
        {bigScreen? <DesktopComponent currentLocation={props.currentLocation} clickedButtonHandler={clickedButtonHandler} chipData={props.chipData} addFilter={props.addFilter} handleClear={props.handleClear}/> : <MobileComponent showDrawer={showDrawer} toggleDrawer={toggleDrawer} clickedButtonHandler={clickedButtonHandler} chipData={props.chipData} addFilter={props.addFilter}/>}
        </div>
    );
} 

function DesktopComponent(props){
    const [filter,setFilter]=useState(1);
    function handleFilterChange(e,updatedFilter){
        setFilter(updatedFilter);
        e.preventDefault();
    }

    return(
        <div>
            <div className="pathBoxContainer">
                Home{props.currentLocation}
            </div>
            <div className="filterContainer-desktop">
                <Root>
                    <div>
                        <Grid container direction='row' alignItems='center' justifyContent='space-between' className="searchBarContainer">
                            
                            <Grid item className="saleExpandBox" md={1}>
                                <div className="expandOption">Sale</div>
                                <img src={ExpandIcon} alt='expand-icon' className="expandIcon"/>
                            </Grid>
                            
                            <Grid item className="searchBox" md={9}>
                                <input className="searchBar" type="text" placeholder="Enter a keyword" />
                            </Grid>
                            
                            <Grid item className="searchButton" md={1}>
                                <img src={SearchIconLight} alt="search-icon" className="searchIcon"/>
                                <div className="searchText">
                                    Search
                                </div>
                            </Grid>

                        </Grid>
                    </div>

                    <Divider className="divider">Filter Settings</Divider>

                    <div className="filterSettingsContainer">

                        <div className="filterBox-desktop">
                            <div className="filterButtons-desktop">
                                {FilterOptions.map((object, i) => {
                                    return (
                                        <div key={i} className={`filterButton-desktop ${object.id===filter?'activeChoice':''}`} onClick={(e) => handleFilterChange(e,object.id)}>
                                            {object.item}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="clearButton" onClick={props.handleClear}>Clear All</div>
                        </div>

                        <div className="filterOptions">
                            <FilterTabs activeFilter={filter} clickedButtonHandler={props.clickedButtonHandler} chipData={props.chipData} addFilter={props.addFilter}/>
                        </div>
                    </div>
                </Root>
            </div>
        </div>
        
    );
}

function MobileComponent(props){
    return(
        <div className="filterContainer-mobile">

            <div className="searchBox-mobile">
                <img src={SearchIconDark} alt='search-icon' className="searchIcon-mobile" />
                <input className="searchBar-mobile" type="text" placeholder="Enter a keyword" />
            </div>

            <div className="filterBox-mobile">
                <Grid container direction='row' justifyContent='space-evenly'>

                    <Grid item className="filterButton-mobile" onClick={props.toggleDrawer(true)} xs={6}>
                        <img src={FilterIcon} alt='filter-icon' className="filterIcon-mobile"/>
                        <div className="filterText-mobile">Filters</div>
                    </Grid>

                    <Grid item className="sortButton" xs={6}>
                        <img src={SortIcon} alt='sort-icon' className="sortIcon"/>
                        <div className="sortText">Sort by</div>
                    </Grid>

                </Grid>
            </div>

            <SwipeableDrawer
            anchor={'bottom'}
            open={props.showDrawer}
            onClose={props.toggleDrawer(false)}
            onOpen={props.toggleDrawer(true)}
            classes={{ paper: "drawerFilterContainer"}}
            PaperProps={{ style: { height: "100%" } }}>
                <DrawerItems toggleDrawer={props.toggleDrawer} clickedButtonHandler={props.clickedButtonHandler} chipData={props.chipData} addFilter={props.addFilter}/>
            </SwipeableDrawer>
        </div>
    );
}

function DrawerItems(props) {
    const [buy,isBuy]=useState(false);

    function RentToBuy() {
        isBuy(true);
    }

    function BuyToRent(){
        isBuy(false);
    }
    return(
        <div className="drawerContainer">

            <div className="closeButton">
                <CloseIcon className="closeIcon" onClick={props.toggleDrawer(false)}/>
            </div>

            <div className="rent-buy-box">
                <Grid container direction='row' justifyContent='space-evenly'>

                    <Grid item className={`rentButton ${buy? '' : 'activeChoice'}`} onClick={BuyToRent}>
                        <div className="rent">Rent</div>
                    </Grid>

                    <Grid item className={`buyButton ${buy?'activeChoice':''}`} onClick={RentToBuy}>
                        <div className="buy">Buy</div>
                    </Grid>

                </Grid>
            </div>

            <div className="selectCityBox-mobile">
                <div className="selectText">
                    Select City
                </div>
                <img src={ExpandIcon} alt="expand-icon" className="expandIcon-drawer"/>     
            </div>

            <div className="searchBox-drawer">
                <img src={SearchIconDark} alt='search-icon' className="searchIcon-drawer" />
                <input className="searchBar-drawer" type="text" placeholder="Select upto 3 localities or landmarks" />
            </div>

            <PropertyTypeFilter buy={buy} bigScreen='false' clickedButtonHandler={props.clickedButtonHandler} chipData={props.chipData} addFilter={props.addFilter}/>
            <BedroomsFilter clickedButtonHandler={props.clickedButtonHandler} chipData={props.chipData} addFilter={props.addFilter}/>
            <BudgetFilter clickedButtonHandler={props.clickedButtonHandler} chipData={props.chipData} addFilter={props.addFilter}/>
            <AvailabilityFilter clickedButtonHandler={props.clickedButtonHandler} chipData={props.chipData} addFilter={props.addFilter}/>
            <FurnishingStatusFilter clickedButtonHandler={props.clickedButtonHandler} chipData={props.chipData} addFilter={props.addFilter}/>

            <div className="viewAllButton">
                View 75,590 Properties
            </div>
        </div>
    );
}

function FilterTabs(props){
    return(
        <>
            {props.activeFilter===1 ? <CityFilter clickedButtonHandler={props.clickedButtonHandler} chipData={props.chipdata} addFilter={props.addFilter}/> 
            : props.activeFilter===3 ? <PropertyTypeFilter clickedButtonHandler={props.clickedButtonHandler} bigScreen='true' chipData={props.chipdata} addFilter={props.addFilter}/>
            : null}
        </>
    );
}

export function CityFilter(props){
    return(
        <div className="cities-filter-desktop">
            <Grid container alignItems='center' justifyContent='space-between' direction='row' className="filter-row">
                {Cities.map((item, i) => {
                    return (
                        <Grid item md={2} key={i}>
                            <Button className='filter-item' onClick={() => props.addFilter(item.city,'city')}  disabled={props.clickedButtonHandler(item.city)}>
                                <Typography>{item.city} </Typography>
                                <Typography className="availableNumber">({item.available})</Typography>
                            </Button>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export function PropertyTypeFilter(props){
    const PropertyTypeBuy = PropertyType.filter(function(object) {
        if (object.type === 'PG/Hostel') {
          return false; 
        }
        return true;
    });
    return(
        <>
            {props.bigScreen==='true' 
            ? 
                <div className="propertyType-desktop">
                    <Grid container alignItems='center' direction='row' className="filter-row">
                        {PropertyType.map((item, i) => {
                            return (
                                <Grid item md={2} key={i}>
                                    <Button className="filter-item" onClick={() => props.addFilter(item.type,'propertyType')} disabled={props.clickedButtonHandler(item.type)}>
                                        {item.type}
                                    </Button>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            :
                <div className="propertyType">
                    <div className="filterHeader-mobile">Property Type</div>
                    <Grid container direction='row' justifyContent='space-between' className="filterItemsGrid">
                        {props.buy?
                            <>
                            {PropertyTypeBuy.map((object, i) => (
                                <Grid item key={i} className="filterItem buy">
                                    <Button className="filterItemButton" onClick={() => props.addFilter(object.type,'propertyType')} disabled={props.clickedButtonHandler(object.type)}>
                                        {object.type}
                                    </Button>
                                </Grid>
                            ))}
                            </>
                        : 
                            <>
                            {PropertyType.map((object, i) => (
                                <Grid item key={i} className="filterItem rent">
                                    <Button className="filterItemButton" onClick={() => props.addFilter(object.type,'propertyType')} disabled={props.clickedButtonHandler(object.type)}>
                                        {object.type}
                                    </Button>
                                </Grid>
                            ))}
                            </>
                        }
                    </Grid>
                </div>
            }
        </>
    );
}

export function BedroomsFilter(props){
    return(
        <div className="bedrooms">
            <div className="filterHeader-mobile">Bedrooms</div>
            <Grid container direction='row' alignItems='center' justifyContent='space-between' className="filterItemsGrid">
                {Bedrooms.map((object, i) => (
                    <Grid item key={i} xs={4} className="filterItem">
                        <Button className="filterItemButton" onClick={() => props.addFilter(object.type,'bedroom')} disabled={props.clickedButtonHandler(object.type)}>
                            {object.type}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export function BudgetFilter(props){
    const [value, setValue] = useState([0,500000]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <div className="budget">
            <div className="filterHeader-mobile">Budget: Rs. 0 to Rs. 5 Lakhs</div>
            <Slider getAriaLabel={() => 'Budget range'} className="budgetSlider" value={value} onChange={handleChange} valueLabelDisplay="auto"
            sx={[
                {'.MuiSlider-thumb':{
                    background: '#FFFFFF !important',
                    border: '2px solid #1C3988'
                }}
            ]}/>
        </div>
    );
}

export function AvailabilityFilter(props){
    return(
        <div className="availability">
            <div className="filterHeader-mobile">Availability</div>
            <Grid container alignItems='center' justifyContent='space-between' className="filterItemsGrid">
                {Availability.map((object, i) => (
                    <Grid item key={i} xs={6} className="filterItem">
                        <Button className="filterItemButton" onClick={() => props.addFilter(object.type,'availability')} disabled={props.clickedButtonHandler(object.type)}>
                            {object.type}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export function FurnishingStatusFilter(props){
    return(
        <div className="furnishingStatus">
            <div className="filterHeader-mobile">Furnishing Status</div>
            <Grid container direction='row' alignItems='center' justifyContent='space-between' className="filterItemsGrid">
                {FurnishingStatus.map((object, i) => (
                    <Grid item key={i} className="filterItem">
                        <Button className="filterItemButton" onClick={() => props.addFilter(object.type,'availability')} disabled={props.clickedButtonHandler(object.type)}>
                            {object.type}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}