import React, { useState } from "react";
import "./cards.scss";

function CourseCard(props) {
  return (
    <div className="couseBox" style={{background: props.text.color, borderRadius: "20px"}}>
        <div style={{marginLeft: "2rem", paddingBottom: "2rem"}}>
      <img src={props.image} style={{height: "2rem", marginTop: "2rem"}} className="" />
      <h3 style={{marginTop: "1.5rem"}}>{props.text.heading}</h3>
      <ul style={{marginTop: "1.5rem", lineHeight: "2rem"}}>
        {props.text.list.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
      </div>
    </div>
  );
}
export default CourseCard;
