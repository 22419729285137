import { Button, Grid,Typography } from '@mui/material'
import React from 'react'
import { useRef } from 'react';
import "./components_bharath.scss";
import Myswipercard from './cards/swiperSlide';
import Left_arrow from '../../../assets/left-arrow.png';
import Right_arrow from '../../../assets/right-arrow.png';

import Estate1 from "../../../assets/class card.png";
import Estate2 from "../../../assets/class card (1).png";
import Estate3 from "../../../assets/class card (2).png";
const windowWidth=window.innerWidth;

const card_details_array=[
    {id:1 , img:Estate1 , cost:'' ,loc:'', descr:'Vinyasa Flow'},
    {id:2 , img:Estate2  , cost:'' ,loc:'', descr:'Hatha Yoga'},
    {id:3 , img:Estate3  , cost:'' ,loc:'', descr:'Mindful Meditation & Pranayama'}
];

export default function TopOffers() {
  const myref = useRef(null);
  const progref=useRef(null);

  const buttonScroll = (element,speed,distance,step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const getScrollPercent=()=>{
    return (`${(myref.current.scrollLeft /(myref.current.scrollWidth - myref.current.clientWidth))*100 }`);
  };
  const scrollProgressFunc =()=>{
      progref.current.style.width = `${getScrollPercent()}%`;
  };


  return (
    <div>
    <Grid container direction='column' id='topOffersContainer' justifyContent='center'>
      
{/* heading */}
        <Grid item className='topOffersItem1'>
            <Typography variant='h2' className='topOffersTitle'>Classes we offer</Typography>
        </Grid>

{/* description and button */}
        <Grid item container direction={{md:'row',xs:'column'}} justifyContent={{md:'space-between',xs:'space-between'}} alignItems={{md:'center',xs:'flex-start'}} className='topOffersItem2' >
            <Grid item md={8}>
                <Typography variant='body1' className='topOffersDescr'>
                    Healthy Mind | Healthy Body | Healthy Lifestyle
                </Typography>
            </Grid>
            {/* <Grid item md={3}>
                <Button variant='outlined' className='topOffersButton'>Show All Offers</Button>
            </Grid> */}
        </Grid>

{/* progress bar and left right buttons */}
      <Grid item container justifyContent='space-between' alignItems='center'className='topOffersItem3'>    
      {windowWidth < 1118 ?  <>
        <Grid item className='progressBarContainer' >
          <div className='progressBar' ref={progref}>
          </div>
        </Grid>
        <Grid item container 
        className='leftRightBtnContainer' spacing={2} md={2}>
          <Grid item>
             <img alt='left' className='leftBtn' src={Left_arrow}
              onClick={()=>{buttonScroll(myref.current, 25, 100, -10);}} />     
          </Grid>
          <Grid item> 
           <img alt='right' className='rightBtn'  src={Right_arrow}
           onClick={()=>{buttonScroll(myref.current, 25, 100, 10);}}/>
          </Grid>                      
        </Grid></> : <></>}
      </Grid>

{/* card carsousel */}
        <Grid item className='topOffersItem4' >
            <div onScroll={scrollProgressFunc} className='manyCardsContainer scroll-div' ref={myref} >
                {card_details_array.map((obj)=>(
                   <Myswipercard key={obj.id} cost={obj.cost} loc={obj.loc} descr={obj.descr} imgsrc={obj.img}/>
                ))}                
            </div>             
        </Grid>
    </Grid>
</div>  
  )
}

