import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import ForRentContent from './pages/forRentContentPage/index';
import Home from "./pages/homePage/index";
import ForSaleContent from './pages/forSaleContentPage/index';
import ForSale from "./pages/forSalePage/index";
import Contactus from "./pages/contactUsPage/index";

function App() {
	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				{/* <Route path="/ForSale" element={<ForSale />} />
				<Route path="/contactus" element={<Contactus />} />
				<Route path='/forSaleContent' element={<ForSaleContent/>} />
				<Route path="/forRentContent" element={<ForRentContent />} /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
