import { Button, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import "./components_bharath.scss";
import HomeBg from "../../../assets/CoverPage.jpg"
// import HomeBg from "../../../assets/Alt_main.png"
// import HomeBg from "../../../assets/AltMain2.jpg"


export default function NavHomeSearch() {
  const windowWidth=window.innerWidth;
  
  return (
  <div style={{height: "90vh", marginTop: "5vh"}}>
    <img src={HomeBg} style={{width:windowWidth, height: "90vh"}} className='homeBg' alt='backgroundimage'/>
      <Grid container direction='column' justifyContent='space-between' 
      className='navHomeContainer'>
         

{/* home section body */}
        <Grid item className='navHomeBodyContainer'>
            <Typography variant='h1' className='bodyTitle'>
            “ Yoga is the golden key that unlocks the door to peace ,tranquility, and joy.” 
            </Typography>
            <Typography className='bodyDescr'variant='body1' >
            - b.k.s iyengar
            </Typography>
        </Grid>

{/* buy and search*/}
        
    </Grid>
    </div>
  )
}
