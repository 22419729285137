import React, { useState } from "react";
import LegalServicesImage from "../../../assets/About_section.png";
import "./components.scss";
import "@fontsource/roboto";
import About_backgorund from "../../../assets/About_backgorund.jpg"

function LegalServices(props) {
  return (
    <div className="legalservicesContainerParent" style={{backgroundImage: `url(${About_backgorund})`, marginTop: "4rem"}}>
    <div className="legalservicesContainer">
    <div className="legalservicesHeader">HELLO & WELCOME!</div>
      <div className="legalservicesHeader">I’m  Himansi Rai</div>
      <div className="legalservicesInfo">
      I’m a yoga alliance ceritified multi style personal yoga and fitness trainer. with expertise in prenatal/postnatal yoga,
yoga therapy, hatha,  vinayasa flow, weight loss yoga, strength training pranayama, and meditation, i bring over a thousand hours of teaching experience.
 <br/> <br/>
A decade ago, my yoga journey began with a goal to touch my toes. little did i know it would evolve into a quest for trust and freedom, enriching an authentic life. now ,i’m here to guide you on a dynamic and tranquil fitness journey, making wellness an
irresitable habit.let’s make every stretch count! 
      </div>

      <div className="legalservicesInfo_1">


      </div>

        <img
          src={LegalServicesImage}
          alt="legal-services"
          className="legalservicesImage"
        />
    </div>
    </div>
  );
}
export default LegalServices;
