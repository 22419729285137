import Hat from "../../../assets/Hat.png";
import Couple from "../../../assets/Couple.png";
import Family from "../../../assets/Family.png";
const Reddata = {
	labels: ["Uneducated", "Pri", "Uni", "High School", "Middle"],
	datasets: [
		{
			data: [25, 15, 15, 10, 35],
			backgroundColor: [
				"rgba(231, 83, 83, 0.2)",
				"rgba(231, 83, 83, 0.6)",
				"rgba(231, 83, 83, 1)",
				"rgba(231, 83, 83, 0.8)",
				"rgba(231, 83, 83, 0.4)",
			],
			borderWidth: 2,
		},
	],
};

const redimage = new Image();
redimage.src = Hat;
const redplugin = {
	id: "custom_canvas_background_image",
	beforeDraw: (chart) => {
		if (redimage.complete) {
			const ctx = chart.ctx;
			const { top, left, width, height } = chart.chartArea;
			const x = left + width / 2 - redimage.width / 2;
			const y = top + height / 2 - redimage.height / 2;
			ctx.drawImage(redimage, x, y);
		} else {
			redimage.onload = () => chart.draw();
		}
	},
};
const Redplugins = [redplugin];

const Redoptions = {
	responsive: true,
	maintainAspectRatio: false,

	plugins: {
		legend: {
			display: true,
			position: "bottom",
			labels: {
				usePointStyle: true,
			},
		},
	},
};

const Yellowdata = {
	labels: ["65+", "15-24", "0-15", "24-25", "45-64"],
	datasets: [
		{
			data: [25, 15, 15, 10, 35],
			backgroundColor: [
				"rgba(254,204,76, 0.2)",
				"rgba(254,204,76, 0.6)",
				"rgba(254,204,76, 1)",
				"rgba(254,204,76, 0.8)",
				"rgba(254,204,76,  0.4)",
			],
			borderWidth: 2,
		},
	],
};
const yellowimage = new Image();
yellowimage.src = Couple;
const yellowplugin = {
	id: "custom_canvas_background_image",
	beforeDraw: (chart) => {
		if (yellowimage.complete) {
			const ctx = chart.ctx;
			const { top, left, width, height } = chart.chartArea;
			const x = left + width / 2 - yellowimage.width / 2;
			const y = top + height / 2 - yellowimage.height / 2;
			ctx.drawImage(yellowimage, x, y);
		} else {
			yellowimage.onload = () => chart.draw();
		}
	},
};
const Yellowplugins = [yellowplugin];
const Yellowoptions = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: true,
			position: "bottom",
			labels: {
				usePointStyle: true,
			},
		},
	},
};

const Greendata = {
	labels: ["Widow", "Divorced", "Married", "Single", "another"],
	datasets: [
		{
			data: [25, 15, 15, 10, 35],
			backgroundColor: [
				"rgba(141,214,156, 0.2)",
				"rgba(141,214,156,0.6)",
				"rgba(141,214,156, 1)",
				"rgba(141,214,156,0.8)",
				"rgba(141,214,156, 0.4)",
			],
			borderWidth: 2,
		},
	],
};
const greenimage = new Image();
greenimage.src = Family;
const greenplugin = {
	id: "custom_canvas_background_image",
	beforeDraw: (chart) => {
		if (greenimage.complete) {
			const ctx = chart.ctx;
			const { top, left, width, height } = chart.chartArea;
			const x = left + width / 2 - greenimage.width / 2;
			const y = top + height / 2 - greenimage.height / 2;
			ctx.drawImage(greenimage, x, y);
		} else {
			greenimage.onload = () => chart.draw();
		}
	},
};
const Greenplugins = [greenplugin];
const Greenoptions = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: true,
			position: "bottom",
			labels: {
				usePointStyle: true,
			},
		},
	},
};

export {
	Reddata,
	Redoptions,
	Redplugins,
	Yellowoptions,
	Yellowdata,
	Yellowplugins,
	Greenoptions,
	Greendata,
	Greenplugins,
};
