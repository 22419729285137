import React from "react";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export default function Linechart() {
	const data = {
		labels: [0, 1, 2, 3, 4, 5, 6, 7, 8],
		datasets: [
			{
				pointRadius: 0,
				label: "First dataset",
				data: [290, 170, 225, 75, 10, 140, 160, 230, 290],
				borderColor: "#9FCBFF",
			},
			{
				pointRadius: 0,
				label: "Second dataset",
				data: [160, 5, 140, 80, 225, 60, 140, 75, 220],
				// fill: false,
				borderColor: "#E75353",
			},
			{
				pointRadius: 0,
				label: "Third dataset",
				data: [80, 60, 175, 20, 160, 90, 210, 160, 225],
				borderColor: "#FECD4C",
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: "top",
			},
		},

		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				grid: {
					display: false,
				},
				min: 0,
				max: 300,
				ticks: {
					stepSize: 75,
				},
			},
		},
	};
	return <Line data={data} options={options} />;
}
