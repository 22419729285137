import HomeIcon from "../assets/home-outline.svg";
import SaleIcon from "../assets/Frame.svg";
import RentIcon from "../assets/key-outline.svg";
import LegalServicesIcon from "../assets/legalservices-icon.svg";
import ProjectsIcon from "../assets/business-outline.svg";
import ContactUsIcon from "../assets/contactus-icon.svg";

export const NAVIGATION_ITEMS = [
    { name: 'Home', path: '/' },
    { name: 'For Sale', path: '/forsale' },
    { name: 'For Rent', path: '/forrent' },
    { name: 'Legal Services', path: '/legalservices' },
    { name: 'Projects', path: '/projects'},
];

export const NAVIGATION_ITEMS_DRAWER= [
    { name: 'Home', path: '/' , icon:HomeIcon},
    { name: 'Sale', path: '/forsale' ,icon:SaleIcon},
    { name: 'Rent', path: '/forrent' ,icon:RentIcon},
    { name: 'Legal Services', path: '/legalservices',icon:LegalServicesIcon},
    { name: 'Projects', path: '/projects' ,icon:ProjectsIcon},
    { name: 'Contact Us', path: '/contactus' ,icon:ContactUsIcon},
];
