import React from "react";
import "./footer.scss";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import Logotitle from "../assets/Yuji_evoke_text.png";
import LinkedIn from "../assets/LinkedIn.svg";
import Facebook from "../assets/Facebook.svg";
import Logo from "../assets/Yuji_evoke_logo.png";
import Instagram from "../assets/Instagram.svg";
import Twitter from "../assets/Twitter.svg";
import { Grid, Stack, Typography } from "@mui/material";

function Footer() {
  return (
    <Grid
      id="footerContainer"
      container
      direction={{ md: "row", xs: "column" }}
      align-items={{ md: "center", xs: "center" }}
      rowSpacing={{ md: 0, xs: 8 }}
      justifyContent={{ md: "space-evenly", xs: "space-evenly" }}
      style={{ marginTop: "20vh" }}
    >
      <Grid
        item
        container
        direction={{ md: "column", xs: "column" }}
        className="firstBox"
        alignItems={{ md: "flex-start", xs: "center" }}
        justifyContent={{ md: "space-between", xs: "space-around" }}
        md={4}
        xs={12}
        order={{ md: 1, xs: 3 }}
        rowSpacing={{ md: 3, xs: 3 }}
      >
        <Grid item className="firstBoxItem1">
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img className="footerLogo" src={Logo} alt="logo" />
            <img className="logoTitle" src={Logotitle} alt="logo-title" />
          </Stack>
        </Grid>

        <Grid item className="firstBoxItem2">
          <Typography className="descr" variant="body1">
            Hey there, I'm Himansi Rai, a Yoga USA Alliance Certified
            Multi-Style Personal Yoga and Fitness Trainer. With expertise in
            prenatal/postnatal yoga, power yoga, yoga therapy, hatha, vinyasa
            flow, weight loss yoga, strength training, pranayama, and
            meditation, I bring over a thousand hours of teaching experience.
          </Typography>
        </Grid>

        <Grid item className="firstBoxItem3">
          <Stack
            direction={{ md: "row", xs: "row" }}
            spacing={{ md: 4, xs: 5 }}
            justifyContent={{ md: "flex-start", xs: "space-evenly" }}
            alignItems={{ md: "flex-start", xs: "center" }}
          >
            {/* <img className='socialIcons' src={Facebook} alt='fb'/> */}
            {/* <img className='socialIcons' src={Twitter} alt='tw'/> */}
            <a
              target="_blank"
              href="https://www.instagram.com/yuj_evoke?igsh=MWtibXBkNzFzZDMzcg=="
            >
              <img className="socialIcons" src={Instagram} alt="Ig" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/yuj-evoke-yogi/about/"
            >
              <img className="socialIcons" src={LinkedIn} alt="ln" />
            </a>
          </Stack>
        </Grid>

        <Grid item className="firstBoxItem3">
          <div>
            Phone: <a href="tel:+91 89048 33875">+91 89048 33875</a>
          </div>
          <div>
            Email:{" "}
            <a href="mailto:yogiwithhimansi@gmail.com">
              yogiwithhimansi@gmail.com
            </a>
          </div>
        </Grid>

        <Grid item className="firstBoxItem3"></Grid>

        <Grid item className="firstBoxItem4">
          <Typography className="copyRights" variant="body1" align="left">
            © 2024. All rights reserved
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction={{ md: "row", xs: "row" }}
        justifyContent={{ md: "space-around", xs: "space-between" }}
        alignItems={{ md: "flex-start", xs: "flex-start" }}
        md={4}
        xs={12}
        order={{ md: 2, xs: 2 }}
        className="secondAndThirdContainer"
      >
        <Grid item className="secondBox">
          <Typography className="secondBoxTitle" variant="h5">
            Take a tour
          </Typography>
          <Stack direction="column" alignItems="flex-start">
            <a className="secondBoxLinks" href="">
              Classes we offer
            </a>
            <a className="secondBoxLinks" href="">
              About Me
            </a>
            <a className="secondBoxLinks" href="">
              Our Services
            </a>
          </Stack>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction={{ md: "column", xs: "column" }}
        justifyContent={{ md: "center", xs: "flex-start" }}
        alignItems={{ md: "flex-start", xs: "center" }}
        rowSpacing={{ md: 3.5, xs: 3.5 }}
        className="fourthBox"
        md={4}
        xs={12}
        order={{ md: 3, xs: 1 }}
      >
        <Grid item>
          <Typography className="fourthBoxTitle" variant="h5">
            Subscribe
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="fourthBoxDescr" variant="body1">
            Subscribe to get latest property, blog news from us
          </Typography>
        </Grid>
        {/* <Grid item className='emailContainer'>
                  <input className='fourthBoxEmail' placeholder='Email Address'/>
                  <ArrowCircleRightRoundedIcon id='emailSendIcon'/> 
              </Grid> */}
      </Grid>
    </Grid>
  );
}

export default Footer;
