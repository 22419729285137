import React, { useState, useEffect } from "react";
import {
	AppBar,
	IconButton,
	Toolbar,
	Box,
	SwipeableDrawer,
} from "@mui/material";
import "./header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/Yuji_evoke_logo.png";
import NammaChavani from "../assets/Yuji_evoke_text.png";
import MenuIcon from "../assets/menu-outline.svg";
import { NAVIGATION_ITEMS } from "./constants";
import { NAVIGATION_ITEMS_DRAWER } from "./constants";

export default function Header(props) {
	const navigate = useNavigate();
	const full_name = "Demo user";
	const screenWidth = window.outerWidth;
	const [showDrawer, setShowDrawer] = useState(false);
	const [scrolled, setScrolled] = useState(false);
	const location = useLocation();

	const handleNavigation = (pathname = "/") => {
		navigate(pathname);
	};

	const toggleDrawer = (isOpen) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setShowDrawer(isOpen);
	};

	const listenScrollEvent = (e) => {
		if (window.scrollY > 100) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};
	useEffect(() => {
		window.addEventListener("scroll", listenScrollEvent);
	}, [listenScrollEvent, screenWidth]);

	return (
		<div>
			{full_name ? (
				<AppBar
					position="fixed"
					className={`root ${scrolled ? "root-scrolled" : ""}`}
				>
					<Toolbar variant="dense" className="toolbar" style={{height: "68px"}}>
						{/* <Box display={{ xs: "block", sm: "block", md: "none" }}>
							<IconButton
								edge="start"
								className="menuButton"
								color="inherit"
								aria-label="menu"
								onClick={toggleDrawer(true)}
							>
								<img src={MenuIcon} alt="menuicon" />
							</IconButton>
						</Box> */}
						<Box className="navbar-logo">
							<img
								src={Logo}
								alt="logo"
								className={`navbarLogo1 ${
									screenWidth <= 960 ? "navbarLogo1-small" : ""
								}`}
							/>
							{/* <IconButton> */}
								<img
									src={NammaChavani}
									alt="namma-chavani"
									className={`navbarLogo2 ${
										screenWidth <= 960 ? "navbarLogo2-small" : ""
									}`}
								/>
							{/* </IconButton> */}
						</Box>
						{/* <Box display={{ xs: "none", sm: "none", md: "block" }}>
							<div className="menu">
								<NavItems
									handleNavigation={handleNavigation}
									location={location}
								/>
							</div>
						</Box> */}
						<Box className="navButton-right">
							<div className="navbarButton">
								<Box display={{ xs: "block", sm: "block", md: "block" }}>
									<a href="https://forms.gle/jYTzXZU5K5v34puf6" target="_blank" type="button" className="navbarcontactusButton" style={{padding: "0.3rem", fontSize: "0.8rem", color: "#937d4d", border: "1px solid #937d4d"}}>
										Contact Us
									</a>
								</Box>
							</div>
						</Box>
					</Toolbar>
					<SwipeableDrawer
						anchor={"left"}
						open={showDrawer}
						onClose={toggleDrawer(false)}
						onOpen={toggleDrawer(true)}
						classes={{ paper: "drawerContainer" }}
					>
						<DrawerItems
							handleNavigation={handleNavigation}
							toggleDrawer={toggleDrawer}
							location={location}
						/>
					</SwipeableDrawer>
				</AppBar>
			) : null}
		</div>
	);
}

function NavItems(props) {
	return (
		<>
			{NAVIGATION_ITEMS.map((item, i) => {
				return (
					<div
						key={i}
						className={`menuItem ${
							props.location.pathname === item.path ? "activeItem" : ""
						}`}
						onClick={() => props.handleNavigation(item.path)}
					>
						<div className="menuName">{item.name}</div>
					</div>
				);
			})}
		</>
	);
}
function DrawerItems(props) {
	return (
		<div className="drawer">
			<Box className="navbar-logo-drawer">
				<IconButton
					edge="start"
					className="menuButton"
					color="inherit"
					aria-label="menu"
					onClick={props.toggleDrawer(false)}
				>
					<img src={MenuIcon} alt="menuicon" className="menuButton-drawer" />
				</IconButton>
				<img src={Logo} alt="logo" className="navbarLogo1-small" />
				<IconButton>
					<img
						src={NammaChavani}
						alt="namma-chavani"
						className="navbarLogo2-small"
					/>
				</IconButton>
			</Box>
			{NAVIGATION_ITEMS_DRAWER.map((item, i) => {
				return (
					<div
						key={i}
						className={`menuItem-drawer ${
							props.location.pathname === item.path ? "activeItem-drawer" : ""
						} ${
							i === NAVIGATION_ITEMS_DRAWER.length - 1 ? "lastItem-drawer" : ""
						}`}
						onClick={() => props.handleNavigation(item.path)}
					>
						<img src={item.icon} alt="menu-icons" className="menuIcon-drawer" />
						<div className="menuName-drawer">{item.name}</div>
					</div>
				);
			})}
		</div>
	);
}
