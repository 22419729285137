import React, { useState } from "react";
import "./components.scss";
import America from "../../../assets/OnlineLogo.png";
import Spain from "../../../assets/PersonalLogo.png";
import London from "../../../assets/TeacherTraining.png";
import CourseCard from "./cards/CoursersCard";

const courseData = [{
  heading: "PERSONAL TRAINING & PAIR TRAINING",
  color: "#fff0e2",
  list: ["Personalised for your health goals", "Dedicated trainer", "Defining your Dosha's imbalance and complete note on how to balance", "Private classes more result oriented sessions.", "Every week feedback sessions on improvement and workout plan change"]
},{
  heading: "OFFLINE CLASSES",
  color: "#fff0e2",
  list: ["Private classes are taken in bangalore.", "Personalised for your health goals", "Dedicated trainer", "Defining your Dosha's imbalance and complete note to on how balance", "Private classes more result oriented sessions.", "Every week feedback sessions on improvement and workout plan change"]
}, {
  heading: "TEACHER TRAINING",
  color: "#fff0e2"
}, {
  heading: "GROUP SESSIONS",
  color: "#fff0e2",
  list: ["Every week innovative  sessions curated to boost motivation","Creates a positive and uplifiting environment ,making your yoga practice much more rewarding.", "Free e-book on how to balance Dosha's", "Free quiz section to identify your imbalanced Dosha's"]
}]

function City(props) {
  return (
    <div className="cityContainer">
      <div className="cityText">
        Our Services
      </div>
      <div className="cityCards">
        <div className="city-tableRow">
          <div className="city-tableCell">
            <CourseCard image={America} text={courseData[0]}/>
          </div>
          <div className="city-tableCell">
          <CourseCard image={Spain} text={courseData[1]}/>
          </div>
          {/* <div className="city-tableCell">
          <CourseCard image={London} text={courseData[2]}/>
          </div> */}
          <div className="city-tableCell">
          <CourseCard image={London} text={courseData[3]}/>
          </div>
        </div>
      </div>
    </div>
  );
}
export default City;